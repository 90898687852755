import Entity from "@/scripts/models/entity.model";
import File from "@/scripts/models/file.model";

export default class Institution {

	constructor() {

		this.id = 0;
		this.entity = new Entity();
		this.ocupationAreaId = null;
		this.stateRegistry = "";
		this.municipalRegistry = "";
		this.mission = "";
		this.yearFundation = null;
		this.scope = "";
		this.stateTitle = true;
		this.municipalTitle = true;
		this.federalTitle = true;
		this.hasAudict = true;
		this.file = new File();
		this.site = "";
		this.facebook = "";
		this.videoURL = "";
		this.instagram = "";
		this.twitter = "";
		this.fileInstitutionList = [];
		this.partnerList = [];
		this.hasLoginSportMinistery = false;
		this.loginSportMinistery = "";
		this.passwordSportMinistery = "";

	}

}