import BaseCrudService from "./baseCrud.service";
import ApiService from "./api.service";

export default class InstitutionService extends BaseCrudService {

	constructor() {
		super('Institution');
	}

	save(callback, institution) {
		this.postRequest('Save', institution).then(callback);
	}

	listAllInstitutionDocumentType(callback) {
		this.getRequest("ListAllInstitutionDocumentType").then(callback);
	}

	listAllOcupationArea(callback) {
		this.getRequest("ListAllOcupationArea").then(callback);
	}

	listAllState(callback) {
		this.getRequest("ListAllState").then(callback);
	}

	listAll(callback) {
		this.getRequest("ListAll").then(callback);
	}

	find(callback, id){
		return this.findById(id, false).then(callback);
	}

	getFileByEntity(entityId) {
		return this.getRequest("GetFileByEntity", {entityId: entityId}, false, false);
	}

	loadInstitutionsInformation(){
		return this.getRequest('LoadInstitutionsInformation');
	}
	
	findByEntityId(entityId){
		return this.getRequest('FindByEntityId', {entityId: entityId});
	}

	listAllFileInstitutionByInstitutionId(institutionId){
		return this.getRequest('ListAllFileInstitutionByInstitutionId', {institutionId: institutionId}); 
	}

}